body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #282c34;
  color: #ffffff;
}

p {
  font-weight: 300;
  margin: 0;
}

.dropZoneArea {
  text-align: center;
  width: 540px;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    height: 60px;
    margin-bottom: 20px;
  }
}

.dragzone {
  border: 1px dashed #eee;
  padding: 20px;
  margin: 0;
  margin-top: 20px;
  border-radius: 6px;
  font-size: 12px;
}

.drag {
  border: 1px solid #FFF;
}

.full-logo {
  height: 40px;
  margin-bottom: 40px;
  margin-top: 20px;
}

.filter {
  display: block;
  position: relative;
  background: #fff;
  border-radius: 2px;
  color: #000;
  padding: 7px 12px;
  padding-left: 138px;
  margin-bottom: 4px;

  select {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid;
  }

  .level, .percent {
    margin-left: 10px;
    margin-right: 5px;
    display: inline-block;
    transform: translateY(-2px);
    width: 70px;
    text-align: right;
  }

  .percent {
    margin-right: 0;
    margin-left: 5px;
    width: 40px;
  }

  .delete {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    opacity: 0.7;
    color: #000;
    &:hover {
      opacity: 1.0;
    }
    font-size: 18px;
    color: red;
    font-weight: bold;
  }

  .angle {
    position: absolute;
    right: 24px;
    font-size: 11px;
    margin-top: -1px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    opacity: 0.7;
    color: #000;
    &:hover {
      opacity: 1.0;
    }
  }

  cursor: move;

  &.placeholder {
    opacity: 0.5;
  }

  .dots {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
}

h3 {
  margin-bottom: 20px;
  margin-top: -5px;
}

.filter-head {
  position: relative;
  .add {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 50%;
    outline: none !important;
    filter: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .btns {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    .btn {
      margin-left: 8px;
    }
  }
}

canvas {
  margin: 0;
  padding: 0;
}

.fs0 {
  font-size: 0px;
  position: relative;
}

.atop {
  position: absolute;
  top: 0;
  left: 0;
}

.picker {
  width: 50px;
  position: absolute;
  top: 50%;
  right: 48px;
  transform: translateY(-50%);
  margin-top: 3px;
  z-index: 10;
}

.color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
}
.popover {
  position: absolute;
  z-index: 150;
  right: 0;
  top: 40px;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.sliders {
  width: 300px;
  display: inline-block;
}

.view-pre {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;

  canvas {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.dropZoneArea {
  .spacer {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .btn-success {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1000px) and (min-height: 632px) {
  .dropZoneArea {
    transform: translate(-50%, -50%) scale(150%);
  }
}

.frames {
  .frame {
    background: #FFF;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .del, .save {
      position: absolute;
      bottom: 8px;
      right: 8px;
      border-radius: 50%;
      height: 38px;
      width: 38px;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .save {
      right: 54px;
    }
  }
}

.urls {
  background: rgba(0,0,0,0.5);
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.atop {
  touch-action: none;
}

@media (max-width: 499px) {
  .filter-head.s-e {
    margin-top: -30px;
    margin-bottom: -10px;
    h3 {
      width: 0;
      overflow: hidden;
    }
    .btns {
      text-align: center;
      a {
        display: none;
      }
      .btn {
        margin-left: 0;
        margin: 0 4px;
      }
      width: 100%;
    }
  }

  .filter {
    padding-left: 10px;
    padding-top: 40px;

    .picker, .angle, .delete {
      top: 8px;
      transform: none;
    }

    .angle {
      margin-top: 5px;
    }

    select {
      top: 10px;
      transform: none;
    }

    .sliders {
      input.text {
        margin-top: 10px;
        max-width: 175px;
      }
      .slider {
        max-width: 120px;
      }
      .level {
        text-align: left;
        width: 55px;
      }
    }
  }
}

.fs0 {
  max-width: 100%;
  max-height: 80vh;

  &.bigh {
    height: 80vh;
  }

  &.bigw canvas {
    // position: absolute;
    // top: 0;
    width: 100%;
  }

  &.bigh canvas {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
}
